import {SVGS} from '../../../../../../assets/images';
import Selector from '../../../../../../components/shared/nextgen/selector';
import React, {useEffect, useState} from 'react';
import {AutoCompleteItemValue} from '../../../../../../components/shared/nextgen/selector/types';
import {IntlShape} from 'react-intl';
import {Lx} from '../../../../../../i18n/consts';
import {TiminatorAbsenceType, TiminatorType} from '../../../../../../features/types/types';

interface Props {
    selectedShiftTypes: Array<AutoCompleteItemValue>;
    selectedAbsenceTypes: Array<AutoCompleteItemValue>;
    shiftTypes: Array<TiminatorType>;
    absenceTypes: Array<TiminatorAbsenceType>;
    onShiftTypesSelected: (types: Array<AutoCompleteItemValue>) => void;
    onAbsenceTypesSelected: (types: Array<AutoCompleteItemValue>) => void;
    intl?: IntlShape;
}

const TypesSelector = (props: Props) => {
    const { shiftTypes, absenceTypes } = props;
    const {  selectedShiftTypes, selectedAbsenceTypes, onShiftTypesSelected, onAbsenceTypesSelected } = props;
    const [source, setSource] = useState<Array<AutoCompleteItemValue>>([]);
    const [selected, setSelected] = useState<Array<AutoCompleteItemValue>>([]);
    const shiftGroupName = props.intl?.formatMessage({ id: Lx.General.SHIFT });
    const absenceGroupName = props.intl?.formatMessage({ id: Lx.General.ABSENCE });

    const handleSetSelectedValues = (values: Array<AutoCompleteItemValue>) => {
        onShiftTypesSelected(values.filter((x) => x.group === shiftGroupName))
        onAbsenceTypesSelected(values.filter((x) => x.group === absenceGroupName))
    }

    useEffect(() => {
        const selections = [...props.selectedShiftTypes, ...props.selectedAbsenceTypes];
        setSelected(source.filter((x) => selections.find((s) => s.group === x.group && s.originalId === x.originalId)));
    }, [selectedShiftTypes, selectedAbsenceTypes]);

    useEffect(() => {
        const sTypes: AutoCompleteItemValue[] = props.shiftTypes.map((x) => ({
            id: x.id,
            name: x.name,
            group: shiftGroupName,
            originalId: x.id
        }));
        const aTypes: AutoCompleteItemValue[] = props.absenceTypes.map((x) => ({
            id: x.id,
            name: x.name,
            group: absenceGroupName,
            originalId: x.id
        }));
        setSource([...sTypes, ...aTypes].map((x, index) => ({ ...x, id: index})));
    }, [shiftTypes, absenceTypes]);

    return(<Selector id={'typeFilter'}
        label={Lx.General.TYPES}
        items={source}
        name={Lx.General.TYPES}
        svg={<SVGS.TasksIcon/>}
        selected={selected}
        setSelectedValues={handleSetSelectedValues}
        intl={props.intl}
        groups={false}/>)
}

export default TypesSelector;