import React from 'react';
import CreateModal from '../../../../../../components/shared/modal/create-modal';
import {useAppDispatch} from '../../../../../../hooks/hooks';
import {addShiftType, getShiftsTypes} from '../../../../../../features/types';
import {TiminatorType} from '../../../../../../features/types/types';
import CreateNewButton from '../../../../../../components/shared/create-new-button';
import useModal from '../../../../../../hooks/useModal';
import {Form, Formik} from 'formik';
import {FormInput} from '../../../../../../components/shared/form-controls/form-input';
import {FormCheckbox} from '../../../../../../components/shared/form-controls/form-checkbox';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { Lx } from '../../../../../../i18n/consts';

interface CreateForm {
    name: string;
    isDefault: boolean;
    isActive: boolean;
}

export default function CreateShiftTypesModal() {
    const dispatch = useAppDispatch();
    const {open, onToggle} = useModal();
    const intl = useIntl();

    const initialValues: CreateForm = {
        name: '',
        isDefault: false,
        isActive: true,
    };

    const validationScheme: Yup.Schema<CreateForm> = Yup.object({
        name: Yup.string()
            .max(45, 'Must be 45 characters or less')
            .required(intl.formatMessage({ id: Lx.Form.NAME_REQUIRED_FORMAT }, { s: intl.formatMessage({ id: Lx.General.SHIFT_TYPE }) })),
        isDefault: Yup.boolean().required(),
        isActive: Yup.boolean().required(),
    })

    const add = async (values: CreateForm) => {
        const type: TiminatorType = {
            id: 0,
            name: values.name,
            active: values.isActive,
            default: values.isDefault,
        }
        return dispatch(addShiftType(type)).unwrap().then(() => {
            dispatch(getShiftsTypes(0));
            onToggle();
        })

    }

    return <>
        <CreateNewButton onClick={onToggle}/>
        {open && <Formik
            initialValues={initialValues}
            validationSchema={validationScheme}
            onSubmit={add}>
            <Form>
                <CreateModal open={open} title='Shift type' onClose={onToggle}>
                    <FormInput name='name' label='Name' placeholder='Shift type' />
                    <fieldset>
                        <FormCheckbox name='isDefault' label='Default' />
                        <FormCheckbox name='isActive' label='Active'/>
                    </fieldset>
                </CreateModal>
            </Form>
        </Formik>}
    </>
}
