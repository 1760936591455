import React from 'react';
import styles from './date-label.module.scss';
import moment from 'moment';
import classNames from 'classnames';

type DateType = 'day' | 'week' | 'month';

const handleDateString = (dateString: string, textType: DateType) => {
    const date = moment(dateString)

    return {
        year: date.format('yyyy'),
        activeText: textType == 'day' ? date.format('D MMM') : textType == 'week' ? `${date.format('W')} Week` : `${date.format('MMMM')}`
    }
}

const DateLabel = (props: {
    value: string;
    type: DateType;
    small?: boolean;
}) => {
    const { activeText, year } = handleDateString(props.value, props.type);

    return(<div className={classNames({
        [styles.labelWrapper]: true,
        [styles.control]: props.small && props.small
    })}>
        <span className={styles.activeText}>{activeText},</span>
        <span className={styles.labelYear}>{year}</span>
    </div>)
}

export default DateLabel