import Calendar from '../base-calendar';
import React, { act, useEffect, useRef, useState } from 'react';
import { CalendarDate, CalendarView, RangeType, Year } from '../../types';
import classNames from 'classnames';
import styles from './popup-calendar.module.scss';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { SVGS } from '../../../../../assets/images';
import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

interface Props {
    year: Year;
    view: CalendarView;
    selectedRange: CalendarDate[];
    range?: boolean;
    onCalendarDateClick?: (CalendarDate: CalendarDate) => void;
    onRangeComplete?: (CalendarDateRange: CalendarDate[]) => void;
    state?: {open: boolean, setOpen: (value: boolean) => void}
}

const formatDateRange = (first: CalendarDate, second: CalendarDate): Array<CalendarDate> => {
    if (second.year < first.year) {
        return [second, first];
    }
    if (second.month < first.month) {
        return [second, first];
    }
    if ((first.month > second.month && first.day <= second.day) || (first.month == second.month && first.day > second.day)) {
        return [second, first];
    }

    return [first, second];
};


// Tried to not copy and paste cod from popup-menu.
const PopupCalendar: React.FC<Props> = (props) => {
    const {year, view, range, state} = props;
    const [open, setOpen] = useState(state?.open ?? false);
    const [firstCalendarDate, setFirstCalendarDate] = useState<CalendarDate | null>(null);
    const [previewCalendarDate, setPreviewCalendarDate] = useState<CalendarDate | null>(range && range ? { year: year.id, month: 0, day: 0 } : null);
    const [rangeTypeState, setRangeTypeState] = useState<{current: RangeType, previous?: RangeType}>({ current: RangeType.Custom });
    const anchorRef = useRef<HTMLDivElement>(null);

    const resetDates = () => {
        if (firstCalendarDate !== null) {
            setRangeTypeState((state) => {
                return {
                    current: state.previous ?? RangeType.Custom,
                    previous: undefined
                }
            })
        }
        setFirstCalendarDate(null);
    }

    const handleToggle = () => {
        setOpen((prevOpen) => {
            state?.setOpen(!prevOpen)
            if (!prevOpen) {
                resetDates();
            }
            return !prevOpen
        });
    };

    const handleClose = (event: Event | React.SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }
        resetDates();
        setOpen(false);
        state?.setOpen(false);
    };

    const prevOpen = React.useRef(open);
    useEffect(() => {
        if (prevOpen.current && !open) {
            anchorRef.current?.focus();
        }

        prevOpen.current = open;
    }, [open]);
    useEffect(() => {
        if (state?.open) {
            setOpen(state.open)
        }
    }, [state?.open]);

    const handleOnDayClick = (year: number, month: number, day: number) => {
        if (range && range) {
            if (rangeTypeState.current !== RangeType.Custom) {
                setRangeTypeState((state) => { 
                    return {
                        current: RangeType.Custom,
                        previous: state.current
                    }
                })
            }
            if (firstCalendarDate == null) {
                setFirstCalendarDate({year, month, day});
            } else {
                const CalendarDates = formatDateRange(firstCalendarDate, {year, month, day});
                setFirstCalendarDate(null);
                setOpen(false);
                props.onRangeComplete?.(CalendarDates);
            }
        } else {
            props.onCalendarDateClick?.({year: year, month: month, day: day});
        }
    };

    const handleOnDayOverEnter = (year: number, month: number, day: number) => {
        if (range && range) {
            if (firstCalendarDate === null) {
                return
            }
            setPreviewCalendarDate((prevDate) => {
                if (prevDate?.year !== year || prevDate?.month !== month || prevDate?.day !== day) {
                    return { year: year, month: month, day: day }
                }
                return prevDate
            })
        }
    };

    const handleOnDayOverLeave = (year: number, month: number, day: number) => {
        return void(0)
    };

    const getMonth = (move?: number): { year: number, month: number, start: number, end: number } => {
        let today = moment();
        if (move) {
            today = today.add(move, 'month')
        }

        const start = today.startOf('month');
        const end = today.clone().endOf('month');

        return { 
            year: parseInt(start.format('YYYY')),
            month: parseInt(start.format('MM')), 
            start: parseInt(start.format('DD')), 
            end: parseInt(end.format('DD')) 
        }
    }

    const getWeek = (move?: number): { year: number, start: { month: number, day: number }, end: { month: number, day: number } } => {
        let today = moment();
        if (move) {
            today = today.add(move, 'week');
        }

        const start = today.startOf('week');
        const end = today.clone().endOf('week');

        return { 
            year: parseInt(start.format('YYYY')),
            start: {
                day: parseInt(start.format('DD')),
                month: parseInt(start.format('MM'))
            },
            end: {
                day: parseInt(end.format('DD')),
                month: parseInt(end.format('MM'))
            }
        }
    }

    const handleActionClick = (action: RangeType) => {
        switch (action) {
            case RangeType.CurrentMonth: {
                const month = getMonth();
                if (action !== rangeTypeState.previous) {
                    props.onRangeComplete?.(formatDateRange({ year: month.year, month: month.month, day: month.start },
                        { year: month.year, month: month.month, day: month.end }))
                }
                break;
            }
            case RangeType.PreviousMonth:{
                const month = getMonth(-1);
                if (action !== rangeTypeState.previous) {
                    props.onRangeComplete?.(formatDateRange({ year: month.year, month: month.month, day: month.start },
                        { year: month.year, month: month.month, day: month.end }))
                }
                break;
            }
            case RangeType.ThisWeek:{
                const month = getWeek();
                if (action !== rangeTypeState.previous) {
                    props.onRangeComplete?.(formatDateRange({ year: month.year, month: month.start.month, day: month.start.day },
                        { year: month.year, month: month.end.month, day: month.end.day }))
                }
                break;
            }
            case RangeType.LastWeek:{
                const month = getWeek(-1);
                if (action !== rangeTypeState.previous) {
                    props.onRangeComplete?.(formatDateRange({ year: month.year, month: month.start.month, day: month.start.day },
                        { year: month.year, month: month.end.month, day: month.end.day }))
                }
                break;
            }
            case RangeType.Custom:
                break;
        }
        if (action !== RangeType.Custom) {
            setOpen(false);
            if (firstCalendarDate !== null) {
                setFirstCalendarDate(null)
            }
            setRangeTypeState((state) => {
                const previousState = {...state}
                return {
                    current: action,
                }
            });
        }
        setRangeTypeState((state) => {
            const previousState = {...state}
            return {
                current: action,
                previous: previousState.current
            }
        });
    }

    return (
        <>
            <div ref={anchorRef}
                id="composition-button"
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                className={classNames({
                    [styles.actionIcon]: true,
                    [styles.active]: open,
                })}
            >
                <>
                    <SVGS.CalendarIcon/>
                </>
            </div>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={'banner'}
                placement={'bottom-start'}
                className={styles.popper}
                transition
            >
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                'center top',
                        }}
                    >
                        <Paper className={styles.paper}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <div className={styles.calendarContainer}>
                                    <div className={styles.actions}>
                                        <h6>Presets</h6>
                                        {
                                            Object.values(RangeType).map((x, index) => {
                                                
                                                return(<Button key={index} variant={'scheduleAction'} sx={{ justifyContent: 'flex-start' }} className={classNames({
                                                    [styles.calendarPresetAction]: true,
                                                    [styles.active]: x == rangeTypeState.current
                                                })} onClick={() => handleActionClick(x)}><FormattedMessage id={x} /></Button>)
                                            })
                                        }
                                    </div>
                                    <span className={styles.separatorLine} />
                                    <Calendar year={year} view={view} size={'small'} 
                                        onDateClick={handleOnDayClick}
                                        onDayOverEnter={handleOnDayOverEnter} 
                                        onDayOverLeave={handleOnDayOverLeave}
                                        selectedRange={props.selectedRange}
                                        double={true}
                                        previewRange={ firstCalendarDate ? [firstCalendarDate, previewCalendarDate ?? {year: 0, month: 0, day: 0}] : undefined}/>
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

export default PopupCalendar;