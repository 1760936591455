import React from 'react';
import styles from './total.module.scss';
import classNames from 'classnames';
import {FormattedMessage, useIntl} from 'react-intl';
import {Lx} from '../../../../i18n/consts';
import Grid from '@mui/material/Grid';

interface Props {
    total: number;
    allTotal: number;
    approved: number;
    billable: number
}

const Total: React.FC<Props> = (props) => {
    const { total, allTotal, approved, billable } = props;

    return (<div className={styles.total}>
        <div className={styles.totalPart}>
            <p><FormattedMessage id={Lx.Schedule.TOTAL_HOURS} />:
            </p>{allTotal < 1 ? total : <span><FormattedMessage id={Lx.General.ITEM_OF_ITEM_FORMAT} values={{f: Math.floor(total), s: Math.floor(allTotal)}}/></span>}
        </div>
        <div className={classNames({
            [styles.totalPart]: true,
            [styles.inactive]: approved < 1,
        })}><p><FormattedMessage id={Lx.Schedule.APPROVED_HOURS} />:</p><span className={styles.totalApproved}>{Math.floor(approved)}</span></div>
        <div className={classNames({
            [styles.totalPart]: true,
            [styles.inactive]: billable < 1,
        })}><p><FormattedMessage id={Lx.Schedule.BILLABLE_HOURS} />:</p><span className={styles.totalBillable}>{Math.floor(billable)}</span></div>
    </div>)
}

export default Total;
