import React, {useEffect} from 'react';
import {useAppDispatch} from '../../../../hooks/hooks';
import useModal from '../../../../hooks/useModal';
import EditModal from '../../../../components/shared/modal/edit-modal';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {FormInput} from '../../../../components/shared/form-controls/form-input';
import {FormCheckbox} from '../../../../components/shared/form-controls/form-checkbox';
import {Employee} from '../../../../features/employees/types';
import {getEmployees, updateEmployee} from '../../../../features/employees';
import FormAutocomplete from '../../../../components/shared/form-controls/form-autocomplete-tag';
import {AutoCompleteItemValue} from '../../../../components/shared/form-controls/form-autocomplete-tag/types';
import {EmployeeFormHandbook} from '../../types';
import {PIdNameModel} from '../../../../features/projects/types';
import {phoneRegex} from '../../../../features/employees/consts';
import {useIntl} from 'react-intl';
import {Lx} from '../../../../i18n/consts';

interface Props extends EmployeeFormHandbook{
    employee: Employee;
}

interface EditForm {
    firstName: string,
    lastName: string,
    email?: string,
    phoneNumber?: string,
    position?: string,
    isActive: boolean,
    admin: boolean,
    roles: PIdNameModel[],
    projects: PIdNameModel[],
}

export default function EditEmployeeModal(props: Props) {
    const intl = useIntl();
    const {employee, roles, projects} = props;
    const dispatch = useAppDispatch();
    const {open, onToggle, openModal} = useModal();
    const id = employee.id;

    const initialValues: EditForm = {
        admin: employee.admin,
        email: employee.email ?? '',
        lastName: employee.lastName ?? '',
        phoneNumber: employee.phoneNumber ?? '',
        position: employee.position ?? '',
        projects: employee.projects,
        roles: employee.roles,
        firstName: employee.firstName  ?? '',
        isActive: employee.isActive,
    };

    const validationScheme: Yup.Schema<EditForm> = Yup.object({
        firstName: Yup.string()
            .max(45, intl.formatMessage({ id: 'max_length_error' }))
            .required(`${intl.formatMessage({ id: Lx.Employee.FIRST_NAME })} ${intl.formatMessage({ id: Lx.Form.REQUIRED })}`),
        lastName: Yup.string()
            .max(45, intl.formatMessage({ id: 'max_length_error' }))
            .required(`${intl.formatMessage({ id: Lx.Employee.LAST_NAME })} ${intl.formatMessage({ id: Lx.Form.REQUIRED })}`),
        email: Yup.string().email().optional(),
        phoneNumber: Yup.string().matches(phoneRegex, intl.formatMessage({ id: Lx.Form.PHONE_NUMBER_ISNT_VALID })).optional(),
        position: Yup.string().optional(),
        isActive: Yup.boolean().required(),
        admin: Yup.boolean().required(),
        roles: Yup.array().of(Yup.object().shape({
            id: Yup.number().required(),
            name: Yup.string().required(),
            isDefault: Yup.boolean().required(),
        })).required().min(0),
        projects: Yup.array().of(Yup.object().shape({
            id: Yup.number().required(),
            name: Yup.string().required(),
            isDefault: Yup.boolean().required(),
        })).required().min(0),
    })

    useEffect(() => {
        openModal();
    }, [])

    const save = async (values: EditForm) => {
        const updated: Employee = {
            id,
            admin: values.admin,
            isActive: values.isActive,
            phoneNumber: values.phoneNumber,
            position: values.position,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            canEditAdminLabel: false,
            canEditProjects: false,
            canEditRoles: false,
            projects: values.projects,
            roles: values.roles,
        }

        return dispatch(updateEmployee(updated)).unwrap().then(() => {
            onToggle();
            dispatch(getEmployees(0));
        })
    }

    return <span>
        {open && <Formik
            initialValues={initialValues}
            validationSchema={validationScheme}
            onSubmit={save}>
            <Form>
                <EditModal open={open} title='employee' onClose={onToggle}>
                    <FormInput name='firstName' label='First Name' placeholder='First Name' />
                    <FormInput name='lastName' label='Last Name' placeholder='Last Name' />
                    <FormInput name='email' label='Email' placeholder='Email' />
                    <FormInput name='phoneNumber' label='Phone Number' placeholder='Phone Number' />
                    <FormInput name='position' label='Position' placeholder='Position' />
                    <FormAutocomplete name='projects' label='Projects' placeHolder='Start typing to add' items={projects as AutoCompleteItemValue[]} selectedItems={employee.projects}/>
                    <FormAutocomplete name='roles' label='Roles' placeHolder='Start typing to add' items={roles as AutoCompleteItemValue[]} selectedItems={employee.roles}/>
                    <fieldset>
                        <FormCheckbox name='admin' label='Admin' />
                        <FormCheckbox name='isActive' label='Active'/>
                    </fieldset>
                </EditModal>
            </Form>
        </Formik>}
    </span>
}
