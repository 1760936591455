import React, {useEffect, useState} from 'react';
import styles from './date-selector.module.scss';
import DateLabel from '../date-label';
import {PopupCalendar} from '../../calendar';
import {Year} from '../../calendar/types';
import classNames from 'classnames';

interface Props extends React.HTMLAttributes<HTMLDivElement>{
    year: Year;
    value: string[]
    onComplete: (values: string[]) => void;
}

const DateSelector: React.FC<Props> = (props) => {
    const { year } = props;
    const [value, setValue] = useState<string[]>(props.value);
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (!open) {
            props.onComplete(value);
        }
    }, [open]);

    return(<div className={classNames({ [styles.selector]: true, [styles.active]: open })} {...props} onClick={() => !open && setOpen(true)}>
        <div className={styles.label}>
            <DateLabel value={value[0]} type={'day'} small />
            {
                value.length > 1 && value[0] !== value[1] && <>
                    <span className={styles.dash}>–</span>
                    <DateLabel value={value[1]} type={'day'} small />
                </>
            }
        </div>
        <PopupCalendar range
            year={year}
            view={'month'}
            state={{ open: open, setOpen: setOpen}}
            selectedRange={value.map((x) => {
                const split = x.split('/').map((x) => parseInt(x));
                return { year: split[0], month: split[1], day: split[2] }
            })}
            onRangeComplete={(value) => {
                setValue(value.map((x) => `${x.year}/${x.month}/${x.day}`))
                setOpen(false)
            }}/>
    </div>)
}

export default DateSelector