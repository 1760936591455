import React, { useEffect, useState } from 'react'
import { Box, Skeleton, Stack } from '@mui/material';
import moment from 'moment/moment';
import DaysInfo from '../day/info';
import MonthView from '../month';
import { WorkCalendar, Day, Month, isCalendar, isDay, isNotEmptyCalendar, IdNameModel } from '../../../../../../features/settings/workcalendar/types';
import {
    getMonth,
    getRegions,
    getWorkCalendars,
    setWorkCalendarId,
    setCurrentYear
} from '../../../../../../features/settings/workcalendar';
import { store } from '../../../../../../store/store';
import { selectIdOnDelete, selectIdOnEdit } from '../../../../../../features/modals';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import EditMonthDayModal from '../day/edit-modal';
import styles from './small-calendar.module.scss';
import { MonthSelector } from '../month/selector';
import { SVGS } from '../../../../../../assets/images';
import { PopupMenuAction } from '../../../../../../components/shared/popup-menu/types';
import EditCalendarModal from '../calendar/edit-modal';
import CreateCalendarModal from '../calendar/create-modal';
import SelectCalendarModal from '../calendar/select-modal';
import DeleteCalendarModal from '../calendar/delete-modal';
import PopupMenuActions from '../../../../../../components/shared/popup-menu';
import { Calendar } from '../../../../../../components/shared/calendar';


const SmallCalendar = () => {
    const dispatch = useAppDispatch();
    const date = moment();
    const { regions, workCalendars, workCalendarId, currentMonth, availableYears, currentYear, year } = useAppSelector(store => store.workCalendar);
    const selectedEdit = useAppSelector(store => store.modals.selectedEdit);
    const selectedDelete = useAppSelector(store => store.modals.selectedDelete);
    const [loading, setLoading] = useState<boolean>(true);
    const [month, setMonth] = useState<number>(parseInt(date.startOf('year').format('M')));

    const availableMonths = moment.months().map((month, i) => {
        return { id: (i + 1), name: month }
    });

    const availableWorkCalendars = workCalendars.map(x => ({ id: x.id, name: x.name }));

    const handleWorkCalendarSelection = async (id: number, year: number) => {
        store.dispatch(setWorkCalendarId(id));
        store.dispatch(setCurrentYear(year))
        await fetchData(id, year, month)
    }

    const fetchWorkCalendar = async () => {
        await fetchData(workCalendarId, currentYear, month)
    }

    const fetchData = async (workCalendarId: number, year: number, month: number) => {
        setLoading(true)
        return dispatch(getMonth({ workCalendarId, year, month })).unwrap().then(() => setLoading(false));
    }

    const handleDayClick = (month: number, day: Day) => {
        dispatch(getMonth({ workCalendarId: workCalendarId, year: currentYear, month: month })).unwrap().then(() => {
            store.dispatch(selectIdOnEdit(day));
        })
    }

    const handleDayUpdateFinished = async () => {
        await updateMonth(currentMonth.month);
    }

    const updateMonth = async (month: number) => {
        setMonth(month);
        await fetchData(workCalendarId, currentYear, month);
    }

    const handleMonthChanged = async (value: string) => {
        const monthNumber = parseInt(value);
        if (month === monthNumber) {
            return;
        }
        await updateMonth(monthNumber);
    }

    const handleCaledarEdit = () => {
        const workCalendar = workCalendars.filter(w => w.id == workCalendarId)[0]
        store.dispatch(selectIdOnEdit(workCalendar))
    }

    const handleCalendarCreate = () => {
        const emptyCalendar: WorkCalendar = {
            id: 0,
            name: '',
            region: 0,
            workHours: 0,
            shortWorkHours: 0
        }
        store.dispatch(selectIdOnEdit(emptyCalendar))
    }

    const handleCalendarSelect = () => {
        const model: IdNameModel = {
            id: workCalendarId,
            name: ''
        }
        store.dispatch(selectIdOnEdit(model))
    }

    const handleCalendarCreated = async () => {
        return await fetchWorkCalendar()
    }

    const handleCalendarDelete = () => {
        const workCalendar = workCalendars.find(w => w.id == workCalendarId)!
        store.dispatch(selectIdOnDelete(workCalendar))
    }

    const handleCalendarDeleted = async () => {
        return await dispatch(getWorkCalendars())
    }

    const calendarActions = (calendarId: number): PopupMenuAction[] => {
        const defaultFunctions = [
            {
                name: 'Create',
                icon: <SVGS.PlusIcon className={styles.addButton}/>,
                callback: function () { handleCalendarCreate() }
            },
            {
                name: 'Edit',
                icon: <SVGS.EditIcon />,
                callback: function () { handleCaledarEdit() }
            },
            {
                name: 'Choose',
                icon: <SVGS.CalendarIcon className={styles.chooseButton} />,
                callback: function () { handleCalendarSelect() }
            },
        ]

        if (calendarId > 1) {
            defaultFunctions.push({
                name: 'Delete',
                icon: <SVGS.DeleteIcon className={styles.deleteButton} />,
                callback: function () { handleCalendarDelete() }
            })
        }

        return defaultFunctions
    }

    useEffect(() => {
        setLoading(true)
        dispatch(getWorkCalendars()).then(() => setLoading(false));
        dispatch(getRegions())
    }, [])

    useEffect(() => {
        if (workCalendarId < 1) return;
        fetchWorkCalendar()
    }, [workCalendarId, currentYear, currentMonth.month])

    const MonthCalendar = (props: { year: number, month: Month }) => {
        const { year, month } = props;
        const dateObject = moment(new Date(`${year}/${month.month}/01`));

        return <Box className={styles.monthWrapper}>
            <Stack direction='column'>
                <Box sx={{ paddingInline: '0.5rem' }}>
                    <MonthSelector items={availableMonths} value={month.month} onChange={handleMonthChanged} />
                </Box>
                <MonthView month={month} onDayClick={(d) => handleDayClick(month.month, month.days.find(x => x.day === d)!)} dateObject={dateObject} />
            </Stack>
        </Box>
    }

    return <Box>
        <Box className={styles.wrapper}>
            <Box className={styles.selectionInfoWrapper}>
                <div className={styles.first}>
                    <Box alignItems={'center'}>
                        {
                            !loading && workCalendars && workCalendarId && availableWorkCalendars.filter(x => x.id == workCalendarId)[0].name
                        }
                    </Box>
                </div>
                <div className={styles.second}>
                    <Box alignItems={'center'}>
                        {
                            !loading && availableYears && currentYear
                        }
                    </Box>
                </div>
                {
                    workCalendars && <div className={styles.actionIconWrapper}>
                        <PopupMenuActions actions={calendarActions(workCalendarId)} rowItem={null} actionIcon={<SVGS.ActionVertIcon style={{cursor: 'pointer'}} />} />
                    </div>
                }
            </Box>
            <Box>
                {
                    !loading && currentYear && currentMonth.month != 0 && currentMonth.days.length > 0 ? <MonthCalendar year={currentYear} month={currentMonth} />
                        : <Skeleton sx={{ bgcolor: 'var(--card-background-color)' }} variant='rectangular' height={310} />
                }
            </Box>
            <DaysInfo />
        </Box>
        {currentYear && selectedEdit && !isCalendar(selectedEdit as WorkCalendar) && !isNotEmptyCalendar(selectedEdit as WorkCalendar) && !isDay(selectedEdit as WorkCalendar) && <SelectCalendarModal calendar={selectedEdit as IdNameModel} calendars={workCalendars} currentYear={currentYear} years={availableYears} onSelect={handleWorkCalendarSelection} />}
        {selectedEdit && isCalendar(selectedEdit as WorkCalendar) && <CreateCalendarModal regions={regions} onCreate={handleCalendarCreated} />}
        {selectedEdit && isNotEmptyCalendar(selectedEdit as WorkCalendar) && <EditCalendarModal regions={regions} calendar={selectedEdit as WorkCalendar} />}
        {selectedEdit && isDay(selectedEdit as Day) && <EditMonthDayModal day={selectedEdit as Day} year={currentYear} month={currentMonth.month} workCalendarId={workCalendarId} onUpdate={handleDayUpdateFinished} />}
        {selectedDelete && <DeleteCalendarModal workCalendar={selectedDelete as WorkCalendar} onDelete={handleCalendarDeleted} />}
    </Box>
}

export default React.memo(SmallCalendar);