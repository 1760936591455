import React, {useState} from 'react';
import ROUTES from '../../routes/routes';
import { MenuItem } from './blocks/menu-item';
import styles from './menu.module.scss';
import {SVGS} from '../../assets/images';
import {Box, Skeleton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {isVisible} from '../../helpers/profileHelper';
import {Part} from '../../features/profile/types';
import {SubscriptionBannerV2 as SubBanner2} from './blocks/subscription-banner';
import {SubscriptionType} from './blocks/subscription-banner/types';
import MediaQuery, {useMediaQuery} from 'react-responsive';
import {ConnectSubscription} from '../../features/billing/subscriptionService';
import {doPayment} from '../../features/payments';
import {showSnack} from '../../features/snacks/snackService';
import classNames from 'classnames';
import CollapseButton from './blocks/collapse-button';

export default function Menu(props: { loading: boolean }) {
    const dispatch = useAppDispatch();
    const profile = useAppSelector(store => store.profile);
    const {subscription} = useAppSelector(store => store.subscription);
    const [open, setOpen] = useState<boolean>(useMediaQuery({ minWidth: 1000 }));
    const isMobile = useMediaQuery({ maxWidth: 1000  });

    const toggle = () => {
        setOpen(prev => !prev);
    }

    const handleSubscriptionClick = async () => {
        if (isMobile){
            toggle()
        }
        const result = await ConnectSubscription();
        if (result) {
            await dispatch(doPayment(window.location.origin)).unwrap().then((result) => {
                if (result.success){
                    return;
                }

                if (result.needThreeDSecure) {
                    showSnack('Redirecting to 3D secure page in 3 seconds...',);
                    const timeout = setTimeout(() => {
                        window.location.replace(result.threeDSecureUrl);
                    }, 3000);
                    return () => clearTimeout(timeout);
                }

                if (result.error) {
                    showSnack(`Unable to do payment. ${result.errorMessage}`, 'error');
                }
            })
        }
    }

    return (<>
        <MediaQuery minWidth={1000}>
            <div className={classNames({
                [styles.desktopMenu]: true,
                [styles.open]: open
            })}>
                <div className={styles.menuWrapper}>
                    <>{ open ? <SVGS.LogoIcon /> : <SVGS.LogoJIcon width={48} height={48} /> }</>
                    <div className={styles.menuItems}>
                        {
                            props.loading ? [...Array(Object.keys(Part).length / 3).keys()]
                                .map((_, index) => (
                                    <Skeleton key={index} sx={{ bgcolor: 'grey.900' }} variant={'rectangular'} width={'100%'} height={44} />
                                ))
                                : <>
                                    {(isVisible(profile, Part.AbsenceTypes)
                                            || isVisible(profile, Part.ShiftTypes)
                                            || isVisible(profile, Part.ProjectTypes))
                                        && <MenuItem route={ROUTES.types} open={open} />}
                                    {isVisible(profile, Part.Roles) && <MenuItem route={ROUTES.roles} open={open} />}
                                    {isVisible(profile, Part.Employees) && <MenuItem route={ROUTES.employees} open={open} />}
                                    {isVisible(profile, Part.Clients) && <MenuItem route={ROUTES.clients} open={open} />}
                                    {isVisible(profile, Part.Projects) && <MenuItem route={ROUTES.projects} open={open} />}
                                    {isVisible(profile, Part.Timesheet) && <MenuItem route={ROUTES.schedule} open={open} />}
                                    {(isVisible(profile, Part.StartAndEndDayTime)
                                            || isVisible(profile, Part.NotificationAtEndOfMonth)
                                            || isVisible(profile, Part.EarlyAccess)
                                            || isVisible(profile, Part.Payments)
                                            || isVisible(profile, Part.WorkCalendar))
                                        && <MenuItem route={ROUTES.settings} open={open} />}
                                    <MenuItem route={ROUTES.manage} open={open} />
                                </>
                        }
                    </div>
                </div>
                <div className={styles.menuBottom}>
                    { isVisible(profile, Part.Subscription) && subscription
                        && <SubBanner2 open={open} type={!subscription.isFree && subscription.subscriptionEnabled ? SubscriptionType.Paid : SubscriptionType.Free} onClick={handleSubscriptionClick} />}
                    <CollapseButton open={open} toggle={toggle} />
                </div>
            </div>
        </MediaQuery>
        <MediaQuery maxWidth={1000}>
            <div className={styles.mobileMenu}>
                <Box>
                    <SVGS.LogoMobileIcon />
                </Box>
                <Box>
                    {!open ? <SVGS.MenuMobileIcon onClick={toggle}/> : <CloseIcon onClick={toggle}/>}
                </Box>
            </div>
            {open && <Box className={styles.opened}>
                <div className={styles.openedMenuWrapper}>
                    {(isVisible(profile, Part.AbsenceTypes)
                            || isVisible(profile, Part.ShiftTypes)
                            || isVisible(profile, Part.ProjectTypes))
                        && <MenuItem route={ROUTES.types} callback={toggle} />}
                    {isVisible(profile, Part.Roles) &&
                        <MenuItem route={ROUTES.roles} callback={toggle} />}
                    {isVisible(profile, Part.Employees)
                        && <MenuItem route={ROUTES.employees} callback={toggle} />}
                    {isVisible(profile, Part.Clients)
                        && <MenuItem route={ROUTES.clients} callback={toggle} />}
                    {isVisible(profile, Part.Projects)
                        && <MenuItem route={ROUTES.projects} callback={toggle} />}
                    {(isVisible(profile, Part.StartAndEndDayTime)
                            || isVisible(profile, Part.NotificationAtEndOfMonth)
                            || isVisible(profile, Part.EarlyAccess)
                            || isVisible(profile, Part.Payments)
                            || isVisible(profile, Part.WorkCalendar))
                        && <MenuItem route={ROUTES.settings} callback={toggle} />}
                    <MenuItem route={ROUTES.manage} callback={toggle} />
                </div>
                <div className={classNames({
                    [styles.subscriptionBanner]: true,
                    [styles.openedSubscriptionWrapper]: true
                })}>
                    {
                        isVisible(profile, Part.Subscription) && subscription
                        && <SubBanner2 open={open} type={!subscription.isFree && subscription.subscriptionEnabled ? SubscriptionType.Paid : SubscriptionType.Free}
                            onClick={handleSubscriptionClick} />
                    }
                </div>
            </Box>}
        </MediaQuery>
    </>)
}
