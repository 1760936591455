import React, {ReactElement, forwardRef, useEffect, ReactNode} from 'react';
import styles from './row-collapse.module.scss'
import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import classNames from 'classnames';
import {Collapse, IconButton} from '@mui/material';

export interface State {
    rowKey: any;
    open: boolean;
    forceCollapse: () => void;
    forceExpand: () => void;
}

interface Props {
    rowKey: any
    rowHeader: ReactElement;
    children: ReactNode[];
    onStateChange: (state: State) => void;
    nested: number
}

export const RowCollapse: React.FC<Props> = (props) => {
    const { rowKey: key, rowHeader, children } = props;
    const [open, setOpen] = React.useState(false);
    const [init, setInit] = React.useState(false);

    const handleButtonClick = () => {
        setOpen((s) => {
            props.onStateChange({
                rowKey: key,
                open: !open,
                forceCollapse: () => setOpen(false),
                forceExpand: () => setOpen(true)
            });

            return !open
        });
    }

    useEffect(() => {
        if (init) {
            return
        }

        props.onStateChange({
            rowKey: key,
            open: open,
            forceCollapse: () => setOpen(false),
            forceExpand: () => setOpen(true)
        })

        setInit(true);
    }, []);

    return(<>
        <div className={classNames({
            [styles.row]: true,
            [styles.nested]: props.nested > 0
        })}>
            <IconButton className={classNames({
                [styles.collapseButton]: true,
                [styles.open]: open,
            })} centerRipple={true} onClick={() => handleButtonClick()}><ChevronRightIcon /></IconButton>
            {rowHeader}
        </div>
        {
            open && <div className={styles.collapse}>
                {
                    children.map((c) => (c))
                }
            </div>
        }
    </>)
}