import { Box } from '@mui/material';
import React, {ReactElement} from 'react';
import styles from './page-wrapper.module.scss';

export default function PageWrapper(props: any) {
    return <Box className={styles.pageWrapper}>
        <div className={styles.container}>
            {props.children}
        </div>
    </Box>
}
