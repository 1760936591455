import React, {useEffect} from 'react';
import moment from 'moment';
import {IMomentContext, MomentContext} from './context';

interface IMomentProvider {
    locale: string;
    children: React.ReactNode;
}

const MomentProvider = ({ locale, children }: IMomentProvider) => {
    useEffect(() => {
        moment.updateLocale(locale, {
            week: {
                dow: 1,
                doy: 7
            },
        })
        moment.locale(locale)
    }, []);

    const config: IMomentContext = {
        locale: locale
    }

    return <MomentContext.Provider value={config}>{children}</MomentContext.Provider>;
}

export { MomentProvider };