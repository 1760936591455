import React, {ReactElement, useEffect, useState} from 'react';
import styles from './selector.module.scss'
import CheckIcon from '@mui/icons-material/Check';
import {AutoCompleteItemValue} from './types';
import useAutocomplete from '@mui/material/useAutocomplete';
import {SVGS} from '../../../../assets/images';
import {AutocompleteGroupedOption,} from '@mui/material';
import classNames from 'classnames';
import {v4 as uuidv4} from 'uuid';
import {IntlShape} from 'react-intl';
import {Lx} from '../../../../i18n/consts';
import Listbox from '../../listbox'

interface Props {
    name: string;
    label: string;
    items: AutoCompleteItemValue[];
    setSelectedValues: (values: AutoCompleteItemValue[]) => void;
    selected?: AutoCompleteItemValue[];
    id?: string;
    svg?: ReactElement;
    intl?: IntlShape;
    // eslint-disable-next-line react-redux/no-unused-prop-types
    maxWidth?: number;
    groups?: boolean;
}

const Selector: React.FC<Props> = (props) => {
    const {name, id, label, svg, items, setSelectedValues, selected, groups, intl} = props;
    const [placeholder, setPlaceholder] = useState(intl ? intl.formatMessage({id: label}) : label);
    const defaultValues: AutoCompleteItemValue[] = (selected ?? []).map(s => {
        return items.find((i) => i.id === s.id) as AutoCompleteItemValue;
    });
    const {
        getRootProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
        setAnchorEl,
        focused,
        getClearProps
    } = useAutocomplete({
        id: 'autocomplete',
        multiple: true,
        options: items,
        getOptionLabel: (option) => option.name,
        onChange: (e, value) => {
            setSelectedValues(value)
        },
        openOnFocus: true,
        disableCloseOnSelect: true,
        getOptionKey: (option) => {
            return option.id
        },
        groupBy: (option) => option.group ?? '',
        isOptionEqualToValue: (option, value) => {
            return option.id == value.id
        },
        filterOptions: (options, state) => options.filter((x) => x.name.toLowerCase().includes(state.inputValue.toLowerCase())),
    });

    useEffect(() => {
        if (name) {
            if (selected && selected.length > 0) {
                setPlaceholder(intl ? `${intl.formatMessage({id: Lx.General.ITEM_OF_ITEM_FORMAT}, {
                    f: selected.length,
                    s: items.length
                })} ${intl.formatMessage({id: name})}` : `${selected.length} of ${items.length} ${name}`);
            } else {
                setPlaceholder(intl ? intl.formatMessage({id: label}) : label);
            }
        }
    }, [selected?.length]);

    return (<div className={classNames({
        [styles.selector]: true,
        [styles.active]: selected && selected.length > 0
    })}>
        <div {...getRootProps()}>
            <div ref={setAnchorEl}
                className={classNames({
                    [styles.inputWrapper]: true,
                    [styles.wrapperFocus]: focused,
                    [styles.wrapperActive]: selected && selected.length > 0,
                    'max-width': props.maxWidth ?? 'auto'
                })}>
                <input {...getInputProps()} placeholder={focused ? '' : placeholder} size={label.length} />
                <div className={styles.right}>
                    <button className={classNames({
                        [styles.clearButton]: true
                    })} {...getClearProps()}><SVGS.CancelIcon/></button>
                    <span className={styles.popperIcon}><SVGS.DropdownIcon id={'dropdown-icon'}/></span>
                </div>
            </div>
        </div>
        {groupedOptions.length > 0 ? (
            <Listbox className={styles.listBox} {...getListboxProps()}>
                {(groupedOptions as AutocompleteGroupedOption<AutoCompleteItemValue>[]).map((group) => (
                    group.group == '' ?
                        group.options.map((option, index) => (
                            <li {...getOptionProps({option, index})} key={uuidv4()}>
                                <span>{option.name}</span>
                                <CheckIcon fontSize="small"/>
                            </li>))
                        : groups === undefined || groups ? <ul key={uuidv4()} className={styles.group}>
                            <span className={styles.header}>{group.group}</span>
                            {
                                group.options.map((option, index) => (
                                    <li {...getOptionProps({option, index})} key={index}>
                                        <span>{option.name}</span>
                                        <CheckIcon fontSize="small"/>
                                    </li>))
                            }
                        </ul> : group.options.map((option) => (
                            <li {...getOptionProps({option, index: option.id})} key={option.id}>
                                <span>{option.name}</span>
                                <CheckIcon fontSize="small"/>
                            </li>))
                ))}
            </Listbox>
        ) : null}
    </div>);
}

export default React.memo(Selector);