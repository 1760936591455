import React, {ReactNode, useEffect} from 'react'
import useSnack from '../../../hooks/useSnack';
import BaseModal from './base-modal';
import {useFormikContext} from 'formik';

interface Props {
    children: ReactNode;
    open: boolean;
    title: string;
    onClose: () => void;
    onConfirm?: () => Promise<void>;
    valid? : boolean;
}

export default function EditModal(props: Props) {
    const {open, title, onClose, onConfirm, valid} = props;
    const { submitForm, isValid, validateForm } = useFormikContext();
    const snack = useSnack();

    const onEdit = async () => {
        try {
            const hasErrors = Object.keys(await validateForm()).length !== 0;
            if (!hasErrors) {
                await submitForm();
                snack(`Updated: ${title}`, 'success');
            }
        }
        catch(error) {
            console.log(error);
        }
    }
    
    useEffect(() => {
        validateForm();
    }, [])

    return <>
        {
            open && <BaseModal open={open} title={`Edit ${title}`} onClose={onClose} onConfirm={onEdit} successTitle='Save' valid={isValid}>
                {props.children}
            </BaseModal>
        }
    </>
}
