import React, { useEffect } from 'react'
import { EditForm } from '../types';
import { useIntl } from 'react-intl';
import useModal from '../../../../../../../hooks/useModal';
import { useAppDispatch } from '../../../../../../../hooks/hooks';
import { IdNameModel, WorkCalendar } from '../../../../../../../features/settings/workcalendar/types';
import { workHoursStringArray } from '../../../types';
import { createWorkCalendar, updateWorkCalendar } from '../../../../../../../features/settings/workcalendar';
import { Form, Formik } from 'formik';
import { FormInput } from '../../../../../../../components/shared/form-controls/form-input';
import { FormDropdown } from '../../form-dropdown';
import CreateModal from '../../../../../../../components/shared/modal/create-modal';
import styles from './create-modal.module.scss'
import { getHours, getRegion } from '../functions';

import * as Yup from 'yup';
import { Lx } from '../../../../../../../i18n/consts';

export interface Props {
    regions: IdNameModel[],
    onCreate: () => Promise<void>;
}

const CreateCalendarModal = (props: Props): JSX.Element => {
    const { onCreate } = props;
    const { open, onToggle, openModal } = useModal();
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const initialValues: EditForm = {
        name: '',
        region: getRegion(270, props.regions),
        workHours: getHours(8),
        shortworkHours: getHours(7)
    }

    const save = async (values: EditForm) => {
        const changedWorkCalendar: WorkCalendar = {
            id: 0,
            name: values.name,
            region: values.region.id,
            workHours: values.workHours.id,
            shortWorkHours: values.shortworkHours.id
        }

        return dispatch(createWorkCalendar({ workCalendar: changedWorkCalendar })).unwrap().then(async () => {
            onToggle();
            await onCreate();
        })
    }

    useEffect(() => {
        openModal();
    }, []);

    return <span>
        {
            open && <Formik
                initialValues={initialValues}
                onSubmit={save}>
                <Form>
                    <CreateModal open={open} onClose={onToggle} title={intl.formatMessage({id: 'work_calendar'})}>
                        <FormInput label={intl.formatMessage({id: 'calendarName'})} name={'name'} />
                        <fieldset>
                            <FormDropdown required label={intl.formatMessage({id: 'region'})} name={'region'} items={props.regions} />
                            <span className={styles.description}>{intl.formatMessage({id: 'edit_work_calendar_info'})}</span>
                        </fieldset>
                        <fieldset>
                            <FormDropdown required label={intl.formatMessage({id: 'workHours_in_day'})} name={'workHours'} items={workHoursStringArray} />
                            <span className={styles.description}>{intl.formatMessage({id: 'edit_work_calendar_info'})}</span>
                        </fieldset>
                        <fieldset>
                            <FormDropdown required label={intl.formatMessage({id: 'workHours_in_shortDay'})} name={'shortworkHours'} items={workHoursStringArray} />
                            <span className={styles.description}>{intl.formatMessage({id: 'edit_work_calendar_info'})}</span>
                        </fieldset>
                    </CreateModal>
                </Form>
            </Formik>
        }
    </span>
}

export default React.memo(CreateCalendarModal)