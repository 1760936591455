import React, {useEffect, useRef, useState} from 'react';
import {GridColumnType, GridIdNameModel} from '../../types';
import styles from './edit-cell.module.scss';
import {GridDropdown, GridDropdownOption} from '../dropdown';
import {Box, CircularProgress, Skeleton} from '@mui/material';

const TextEditCell = (props: { value: string, onChange: (value: any) => void, active: boolean }) => {
    const { active } = props;
    const ref = useRef(null);
    useEffect(() => {
        if (!active) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref.current.blur();
        }
    }, [active]);
    return (<textarea autoFocus={true} className={styles.text} value={props.value} onChange={(e) => props.onChange(e.target.value)}/>)
}

const SelectEditCell = (props: { value: number, options: GridIdNameModel[], getOptions?: () => Promise<GridIdNameModel[]>, active: boolean, onChange: (value: any) => void }) => {
    const { value, options, getOptions, active, onChange } = props;
    const ref = useRef<HTMLButtonElement>(null);
    const [internalOptions, setInternalOptions] = useState<GridIdNameModel[] | undefined>(() => {
        if (typeof options === 'function') {
            return undefined
        }

        return options
    })

    useEffect(() => {
        if (!active) {
            ref.current?.blur();
        } else {
            if (getOptions) {
                getOptions().then((r) => {
                    setInternalOptions(r)
                })
            }
        }
    }, [active]);

    return(<GridDropdown ref={ref} 
        multiple={false} 
        value={value}
        autoFocus={true}
        aria-expanded={active}
        aria-haspopup={true}
        listboxOpen={active}
        onChange={(event, value) => onChange(value)}>
        {
            internalOptions ? internalOptions.map((o, index) => (
                <GridDropdownOption key={o.id} value={o.id}>{o.name}</GridDropdownOption>
            ))
                : <Box display={'flex'} width={'100%'} justifyContent={'flex-start'} flexDirection={'column'}>
                    {
                        [1, 2, 3, 4].map((_x, index) => (<div key={index} className={styles.skeletonWrapper}><Skeleton animation={'wave'} /></div>))
                    }
                </Box>
        }
    </GridDropdown>)
}

interface EditCellProps {
    type: GridColumnType;
    value: any;
    onChange: (value: any) => void;
    active: boolean;
    options?: GridIdNameModel[];
    getOptions?: () => Promise<GridIdNameModel[]>;
}

const EditCell = (props: EditCellProps) => {
    const { type, value, options, getOptions, active, onChange } = props;

    switch (type) {
        case 'text':
            return (<TextEditCell value={value} onChange={onChange} active={active}/>);
        case 'singleSelect':
            return getOptions ? (<SelectEditCell value={value} options={[]} getOptions={getOptions} onChange={onChange} active={active} />)
                : (<SelectEditCell value={value} options={options ?? []} onChange={onChange} active={active} />)
        default:
            return (<></>)
    }
}

export default EditCell