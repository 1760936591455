import styles from '../form-controls/form-dropdown/form-dropdown.module.scss';
import React, {CSSProperties, useState} from 'react';

interface Props {
    label?: string;
    name: string;
    value: string | number | readonly string[] | undefined;
    items: {id: number, name: string}[];
    onBlur?: (value: string) => void;
    onChange?: (value: string) => void;
    placeholder?: string;
    required?: boolean;
    id?: string;
    style?: CSSProperties;
}

export const Dropdown = (props: Props) => {
    const [value, setValue] = useState<string | number | readonly string[] | undefined>(props.value);    
    
    const handleChange = (value: string) => {
        setValue(value);
        props.onChange?.(value);
    }
    
    const handleBlur = (value: string) =>{
        props.onBlur?.(value);
    }

    return (
        <>
            <div>
                {props.label && <label htmlFor={props.id || props.name}>{props.label} {!props.required ? (
                    <span className={styles.optional}>(optional)</span>) : ''}</label>}
                <select style={props.style} required={props.required} onBlur={(e) => handleBlur(e.target.value)}
                    onChange={(e) => handleChange(e.target.value)} name={props.name} value={value} className={styles.dropdown}>
                    { props.placeholder && <option value="">{props.placeholder}</option> }
                    {
                        props.items.map(option => <option key={option.id} value={option.id}>{option.name}</option>)
                    }
                </select>
            </div>
        </>
    );
};