import React from 'react';
import Total from '../../total';
import styles from './row-header.module.scss';
import {Box, Button, LinearProgress} from '@mui/material';
import {IntlShape} from 'react-intl';
import {Lx} from '../../../../../i18n/consts';
import ScheduleActionMenu from '../../action-menu';
import classNames from 'classnames';
import ProgressIndicator from '../../progress-indicator';

interface Props {
    name: string,
    total: number,
    all: number
    approved: number,
    billable: number,
    approveAll: number;
    billAll: number;
    totalWorkHours: number;
    intl: IntlShape;
    onApproveAllClick: () => void;
    onBillAllClick: () => void;
    onDisapproveAllClick: () => void;
    onCancelAllBillingClick: () => void;
    onAddNewShiftClick: () => void;
    hideButtons?: boolean;
}

const DataRowHeader: React.FC<Props> = (props) => {
    return(<Box className={styles.container}>
        <span className={styles.name}>{props.name}</span>
        <div className={styles.info}>
            <Total total={props.total} allTotal={props.all} approved={props.approved} billable={props.billable} />
            {
                props.hideButtons === undefined || !props.hideButtons && <>
                    <Button onClick={() => props.approveAll > 0 ? props.onApproveAllClick() : props.onDisapproveAllClick()}
                        variant={'scheduleAction'}
                        className={styles.button}
                        disabled={props.approveAll < 1 && props.approved < 1}
                        sx={{minWidth: '7.8rem'}}>
                        {props.approveAll > 0 || props.approved < 1 ? props.intl.formatMessage({id: Lx.Schedule.APPROVE_ALL_FORMAT}, {s: Math.floor(props.approveAll)})
                            : props.intl.formatMessage({id: Lx.Schedule.DISAPPROVE_FORMAT}, {s: Math.floor(props.approved)})}
                        <span className={classNames({
                            [styles.indicator]: true,
                            [styles.active]: props.approveAll < 1 && props.approved > 0
                        })}>
                            <ProgressIndicator type={'approve'} value={(props.approved / props.totalWorkHours) * 100}/>
                        </span>
                    </Button>
                    <Button onClick={() => props.billAll > 0 ? props.onBillAllClick() : props.onCancelAllBillingClick()}
                        variant={'scheduleAction'} disabled={props.billAll < 1 && props.billable < 1}
                        className={styles.button}
                        sx={{minWidth: '7.8rem'}}>
                        {
                            props.billAll > 0 || props.billable < 1 ? props.intl.formatMessage({id: Lx.Schedule.BILL_ALL_FORMAT}, {s: Math.floor(props.billAll)})
                                : props.intl.formatMessage({id: Lx.Schedule.CANCEL_BILL_FORMAT}, {s: Math.floor(props.billable)})
                        }
                        <span className={classNames({
                            [styles.indicator]: true,
                            [styles.active]: props.billAll < 1 && props.billable > 0
                        })}>
                            <ProgressIndicator type={'billable'} value={(props.billable / props.totalWorkHours) * 100} />
                        </span>
                    </Button>
                    <ScheduleActionMenu intl={props.intl} approvedHours={props.approved}
                        billableHours={props.billable} onCancelBillAllClick={props.onCancelAllBillingClick} onDisapproveAllClick={props.onDisapproveAllClick} onCreateNewShift={props.onAddNewShiftClick} />
                </>
            }
        </div>
    </Box>)
}

export default DataRowHeader