import React, {useEffect, useRef, useState} from 'react';
import styles from './popup-menu.module.scss';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import {v4 as uuidv4} from 'uuid';
import {PopupMenuAction, PopupMenuSize} from './types';
import classNames from 'classnames';
import Button from '@mui/material/Button';

interface Props {
    actionIcon: React.ReactElement,
    actions: Array<PopupMenuAction>,
    size?: PopupMenuSize,
    rowItem?: any;
    newGen?: boolean;
}

export default function PopupMenuActions(props: Props) {
    const {actions, rowItem, size} = props;
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event | React.SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        event.preventDefault();
        event.stopPropagation();
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    const prevOpen = React.useRef(open);
    useEffect(() => {
        if (prevOpen.current && !open) {
            anchorRef.current?.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div ref={anchorRef}
            id="composition-button"
            aria-controls={open ? 'composition-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            className={classNames({
                [styles.menu]: true,
                [styles.active]: open,
                [styles.small]: size ? size == 'small' : false,
            })}
        >
            <Button className={classNames({
                [styles.actionIcon]: size === undefined || size !== 'small',
                [styles.smallActionIcon]: size && size === 'small',
                [styles.active]: open,
                [styles.round]: props.newGen === undefined || !props.newGen
            })}>
                {props.actionIcon}
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement={'bottom-start'}
                className={styles.popper}
                transition
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                    >
                        <Paper className={styles.paper}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                    className={styles.actionMenu}
                                >
                                    {actions?.map((action: PopupMenuAction) => {
                                        return (
                                            <MenuItem key={uuidv4()} onClick={(e) => {
                                                handleClose(e)
                                                return action.callback(rowItem);
                                            }} className={styles.actionItem}>
                                                {action.icon}
                                                <span className={styles.iconText}>{action.name}</span>
                                            </MenuItem>)
                                    })}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    )
}