export type CalendarView = 'month' | 'weeks' | 'year';

export type DayType = 'default' | 'short' | 'off';

export type Size = 'small' | 'full';

export type Day = {
    id: number;
    type: DayType;
}

export type Month = {
    id: number;
    days: Array<Day>
}

export type Year = {
    id: number;
    months: Array<Month>
}

export type CalendarDate = {
    year: number;
    month: number;
    day: number;
}

export type InRangeResult = {
    commit: boolean;
    start: boolean;
    end: boolean;
}

export enum RangeType {
    CurrentMonth = 'currentMonth',
    PreviousMonth = 'previousMonth',
    ThisWeek = 'thisWeek',
    LastWeek = 'lastWeek',
    Custom = 'custom'
}