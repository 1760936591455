import React from 'react'
import PageWrapper from '../../components/shared/page-wrapper';
import {Box, Button, Tab, Tabs} from '@mui/material';
import {useAppSelector} from '../../hooks/hooks';
import {SVGS} from '../../assets/images';
import styles from './manage.module.scss'
import {store} from '../../store/store';
import {logout} from '../../features/auth/authSplice';
import {FormattedMessage, useIntl} from 'react-intl';
import {isVisible} from '../../helpers/profileHelper';
import {Part} from '../../features/profile/types';
import TabPanel from '../../components/shared/tab-panel';
import Payments from './block/payments';
import Subscription from './block/subscrption';
import Profile from './block/profile';

export default function ProfilePage() {
    const [value, setValue] = React.useState(0);
    const profile = useAppSelector((store) => store.profile)
    const intl = useIntl();

    const logoutMe = () => store.dispatch(logout())

    const handleLogOut = () => {
        logoutMe()
    }

    const handleChange = (event: React.SyntheticEvent, value: number) => {
        setValue(value)
    }

    return <PageWrapper>
        <Box justifyContent='space-between' display='flex' alignItems='start'>
            <h3><FormattedMessage id={'manage'} /></h3>
            <Button variant='createNew' onClick={() => handleLogOut()} className={styles.logout}>
                <SVGS.LogOutIcon /><span style={{marginLeft: 10}}>{intl.formatMessage({id: 'log_out'})}</span>
            </Button>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={styles.profileName}>
            <Tabs value={value} onChange={handleChange} scrollButtons='auto' variant='scrollable'>
                <Tab disableRipple={true} label={intl.formatMessage({ id: 'profile' })} />
                { isVisible(profile, Part.Payments) && <Tab disableRipple={true} label={intl.formatMessage({ id: 'payments' })} /> }
                { isVisible(profile, Part.Payments) && <Tab disableRipple={true} label={intl.formatMessage({id: 'subscription'})}/>}
            </Tabs>
        </Box>
        <TabPanel index={value} value={0}>
            <Profile intl={intl} />
        </TabPanel>
        <TabPanel index={value} value={1}>
            <Payments intl={intl} />
        </TabPanel>
        <TabPanel index={value} value={2}>
            <Subscription intl={intl} />
        </TabPanel>
    </PageWrapper>
};
