import {ReactElement, ReactNode} from 'react';
import {InRangeResult, Month} from '../../types';
import moment from 'moment/moment';

export interface MonthViewProps {
    onDayClick: (day: number) => void;
    onDayOverEnter: (day: number) => void;
    onDayOverLeave: (day: number) => void;
    month: Month;
    dateObject: moment.Moment;
    range?: { month: number, day: number };
    getDayIsInRange: (day: number) => InRangeResult | undefined;
}

export interface MonthViewBodyProps {
    startBlanks: Array<MonthDayCell>;
    endBlanks: Array<MonthDayCell>;
    days: Array<MonthDayCell>;
    isRangeEnabled: boolean;
    isInRange: (day: number) => InRangeResult | undefined;
    onDayOver?: (day: number) => void;
    onDayLeave?: (day: number) => void;
}

export interface MonthViewRowProps {
    children: MonthDayCell[] | ReactElement[];
    header?: boolean;
    isRangeEnabled: boolean;
    isInRange: (day: number) => InRangeResult | undefined;
    onDayOver?: (day: number) => void;
    onDayLeave?: (day: number) => void;
}

export interface MonthViewRowCellProps {
    children: ReactNode;
    isDay: boolean;
    header?: boolean;
    isRangeEnabled: boolean;
    onOver?: () => void;
    onLeave?: () => void;
    isInRange?: () => InRangeResult | undefined
}

export interface MonthViewReducerState {
    startBlankDays: Array<MonthDayCell>;
    endBlankDays: Array<MonthDayCell>;
    days: Array<MonthDayCell>;
}

export type MonthDayCell = {
    day: number;
    content: ReactElement;
}

export enum MonthViewReducerUpdateActionType {
    RELOAD_DAYS = 'RELOAD_DAYS',
    FULL_RELOAD = 'FULL_RELOAD'
}

export type MonthViewReducerUpdateAction = {
    type: MonthViewReducerUpdateActionType;
}