import {useField} from 'formik';
import classNames from 'classnames';
import styles from './form-textarea.module.scss';
import React from 'react';

interface Props extends React.InputHTMLAttributes<HTMLTextAreaElement> {
    label: string;
    name: string;
    placeholder?: string;
    stretch?: boolean;
    resize?: 'all' | 'vertical' | 'horizontal'
}

const getResizeClass = (resize: 'all' | 'vertical' | 'horizontal'): string => {
    switch (resize) {
        case 'vertical':
            return styles.verticalResize;
        case 'horizontal':
            return styles.horizontalResize;
        default:
            return ''
    }
}

export const FormTextarea = (props: Props) => {
    const [field, meta] = useField(props);
    const stretch = props.stretch ? props.stretch : false;
    return (
        <label htmlFor={props.id || props.name} className={classNames({
            [styles.wrapper]: true,
            [styles.stretch]: stretch,
            [styles.auto]: !stretch
        })}>
            {props.label} {props.required==false ? (<span className={styles.optional}>(optional)</span>) : '' }
            <textarea className={props.className + ' ' + props.resize && props.resize ? getResizeClass(props.resize) : ''}
                style={{marginBottom: '0.3rem'}}
                aria-invalid={meta.error ? !!meta.error : undefined}
                {...field}
                {...props} />
            {meta.error ? (<div className={styles.error}>{meta.error}</div>) : null}
        </label>
    );
}