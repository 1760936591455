import React from 'react';
import {useAppDispatch} from '../../../../hooks/hooks';
import useModal from '../../../../hooks/useModal';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {FormInput} from '../../../../components/shared/form-controls/form-input';
import {FormCheckbox} from '../../../../components/shared/form-controls/form-checkbox';
import CreateNewButton from '../../../../components/shared/create-new-button';
import CreateModal from '../../../../components/shared/modal/create-modal';
import {PIdNameModel, Project} from '../../../../features/projects/types';
import {addProject, getProjects} from '../../../../features/projects';
import {REmployees} from '../../../../features/roles/types';
import FormAutocomplete from '../../../../components/shared/form-controls/form-autocomplete-tag';
import {FormDropdown} from '../../../../components/shared/form-controls/form-dropdown';
import {filterProjects} from '../../../../features/projects/filter';
import { Lx } from '../../../../i18n/consts';
import { useIntl } from 'react-intl';

interface CreateForm {
    name: string,
    isActive: boolean,
    client?: PIdNameModel,
    projectType: PIdNameModel,
    projectManager?: PIdNameModel,
    roleForNotification?: PIdNameModel,
    employees?: REmployees[],
    note?: string,
}

interface Props {
    currentType: PIdNameModel,
    employees: REmployees[],
    types: PIdNameModel[],
    clients: PIdNameModel[],
    roles: PIdNameModel[]
}

export default function CreateProjectModal(props: Props) {
    const dispatch = useAppDispatch();
    const {open, onToggle} = useModal();
    const intl = useIntl();

    const initialValue: CreateForm = {
        name: '',
        isActive: true,
        projectType: props.currentType,
        employees: [],
        note: ''
    };

    const validateName = async (name: string): Promise<boolean> => {
        name = name.toLowerCase();
        if (name === '') {
            return Promise.resolve(false);
        }

        return await dispatch(filterProjects(name)).unwrap().then((values) => {
            return values.length < 1 || values.some(value => value.name.toLowerCase() !== name);
        });
    }

    const add = async (values: CreateForm) => {
        const updated: Project = {
            id: 0,
            name: values.name,
            isActive: values.isActive,
            note: values.note ?? '',
            client: values.client,
            projectType: values.projectType,
            projectManager: values.projectManager,
            roleForNotification: values.roleForNotification,
            employees: values.employees ?? [],
            canEditClient: true,
            canEditProjectManager: true,
            canEditEmployees: true,
            canEditRoleForNotification: true
        }
        return dispatch(addProject(updated)).unwrap().then(() => {
            dispatch(getProjects({projectTypeId: props.currentType.id, page: 0}));
            onToggle();
        })
    }

    const validationScheme: Yup.Schema<CreateForm> = Yup.object().shape({
        name: Yup.string()
            .max(45, 'Must be 45 characters or less')
            .required(intl.formatMessage({ id: Lx.Form.NAME_REQUIRED_FORMAT }, { s: intl.formatMessage({ id: Lx.General.PROJECT }) }))
            .test(
                'project-name-backend-validation',
                'Project name is taken',
                async (name: string) => { return await validateName(name); }
            ),
        isActive: Yup.boolean().required(),
        projectType: Yup.object().shape({
            id: Yup.number().required(),
            name: Yup.string().required(),
            isDefault: Yup.boolean().required(),
        }).required()
    })

    return <>
        <CreateNewButton onClick={onToggle}/>
        {open && <Formik
            initialValues={initialValue}
            validationSchema={validationScheme}
            validateOnBlur={true}
            validateOnChange={false}
            onSubmit={add}>
            <Form>
                <CreateModal open={open} title='project' onClose={onToggle}>
                    <FormInput name='name' label='Name' placeholder='Name'/>
                    <fieldset>
                        <FormCheckbox name='isDefault' label='Default' />
                        <FormCheckbox name='isActive' label='Active'/>
                    </fieldset>
                    <FormDropdown
                        label='Client'
                        name='client'
                        required={false}
                        placeholder={''}
                        items={props.clients}/>
                    <FormDropdown
                        label='Type'
                        name='projectType'
                        required={true}
                        initialValue={props.currentType}
                        items={props.types}/>
                    <FormDropdown
                        label='Project manager'
                        name='projectManager'
                        required={false}
                        placeholder={''}
                        items={props.employees}/>
                    <FormAutocomplete
                        placeHolder='Start typing to add'
                        label='Employee'
                        name='employees'
                        items={props.employees}/>
                    <FormDropdown
                        label='Role for notification'
                        name='roleForNotification'
                        required={false}
                        placeholder={''}
                        items={props.roles}/>
                    <FormInput name='note' label='Note' placeholder='Write something' required={false}/>
                </CreateModal>
            </Form>
        </Formik>}
    </>
}
