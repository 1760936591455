import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import HttpClient from '../../api/api';
import {PIdNameModel, Project, Projects} from './types';

const initialState: Projects = {
    types: [],
    items: [],
    offset: 0,
    total: 0,
    canCreateNewProject: false,
    canEditRoleForNotification: false,
    canEditProjectManager: false,
    canEditEmployees: false,
}

export const getProjectTabs = createAsyncThunk(
    'projects-type/get-tabs',
    async () => {
        const response = await HttpClient.projectApi.getProjectTabs<PIdNameModel[]>();
        return response.data
    }
)

export const getClients = createAsyncThunk(
    'projects-client/filter',
    async () => {
        const response = await HttpClient.projectApi.getProjectTabs<PIdNameModel[]>();
        return response.data
    }
)

export const getProjects = createAsyncThunk(
    'projects/get',
    async (params: {projectTypeId: number, page: number}) => {
        const response = await HttpClient.projectApi.getProjects<Projects>(params.projectTypeId, params.page);
        return response.data
    }
)

export const addProject = createAsyncThunk(
    'projects/create',
    async (project: Project) => {
        const response = await HttpClient.projectApi.add<Project>(project);
        return response.data
    }
)

export const updateProject = createAsyncThunk(
    'projects/update',
    async (project: Project) => {
        const response = await HttpClient.projectApi.update<Project>(project);
        return response.data
    }
)

export const deleteProject = createAsyncThunk(
    'projects/delete',
    async (id: number) => {
        const response = await HttpClient.projectApi.delete<Project>(id);
        return response.data
    }
)

export const getProject = createAsyncThunk(
    'projects/get-byid',
    async (id: number) => {
        const response = await HttpClient.projectApi.getById<Project>(id);
        return response.data;
    }
)

const typeSplice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        clearProjects: (state) => {
            return initialState
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProjects.fulfilled, (state, action) => {
            if (action.payload.offset === 0) {
                return {...state, ...action.payload}
            } else {
                state.items = [...state.items, ...action.payload.items];
                state.offset = action.payload.offset;
            }
        });
        builder.addCase(getProjectTabs.fulfilled, (state, action) => {
            state.types = action.payload;
        })
    }
})

export const { clearProjects } = typeSplice.actions

export default typeSplice.reducer;
