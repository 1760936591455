export const dayTimes = [
    {id: 1, time: '00:00'},
    {id: 2, time: '01:00'},
    {id: 3, time: '02:00'},
    {id: 4, time: '03:00'},
    {id: 5, time: '04:00'},
    {id: 6, time: '05:00'},
    {id: 7, time: '06:00'},
    {id: 8, time: '07:00'},
    {id: 9, time: '08:00'},
    {id: 10, time: '09:00'},
    {id: 11, time: '10:00'},
    {id: 12, time: '11:00'},
    {id: 13, time: '12:00'},
    {id: 14, time: '13:00'},
    {id: 15, time: '14:00'},
    {id: 16, time: '15:00'},
    {id: 17, time: '16:00'},
    {id: 18, time: '17:00'},
    {id: 19, time: '18:00'},
    {id: 20, time: '19:00'},
    {id: 21, time: '20:00'},
    {id: 22, time: '21:00'},
    {id: 23, time: '22:00'},
    {id: 24, time: '23:00'}
]

export const timeZones= [
    {id: 14, name: '+14:00'},
    {id: 13, name: '+13:00'},
    {id: 12, name: '+12:00'},
    {id: 11, name: '+11:00'},
    {id: 10, name: '+10:00'},
    {id: 9, name: '+09:00'},
    {id: 8, name: '+08:00'},
    {id: 7, name: '+07:00'},
    {id: 6, name: '+06:00'},
    {id: 5, name: '+05:00'},
    {id: 4, name: '+04:00'},
    {id: 3, name: '+03:00'},
    {id: 2, name: '+02:00'},
    {id: 1, name: '+01:00'},
    {id: 0, name: '00:00'},
    {id: -1, name: '-01:00'},
    {id: -2, name: '-02:00'},
    {id: -3, name: '-03:00'},
    {id: -4, name: '-04:00'},
    {id: -5, name: '-05:00'},
    {id: -6, name: '-06:00'},
    {id: -7, name: '-07:00'},
    {id: -8, name: '-08:00'},
    {id: -9, name: '-09:00'},
    {id: -10, name: '-10:00'},
    {id: -11, name: '-11:00'},
    {id: -12, name: '-12:00'},
]