import React, {useState} from 'react';
import styles from './form-dateselector.module.scss';
import DateSelector from '../../date-selector';
import {Year} from '../../../calendar/types';
import {useFormikContext} from 'formik';

interface Props {
    label: string;
    name: string;
    year: Year;
    required?: boolean;
}

const FormDateSelector = (props: Props) => {
    const { setFieldValue, values, getFieldMeta } = useFormikContext<string[]>()
    const meta = getFieldMeta<string[]>(props.name)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const value = values[props.name]

    const handleChange = (values: string[]) => {
        setFieldValue(props.name, values, props.required ?? false);
    }

    return (
        <label htmlFor={props.name} className={styles.label}>
            {props.label} {props.required==false ? (<span className={styles.optional}>(optional)</span>) : '' }
            <DateSelector year={props.year} value={value} onComplete={handleChange} />
            {meta.error ? (<div className={styles.error}>{meta.error}</div>) : null}
        </label>
    )
}

export default FormDateSelector