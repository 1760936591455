import React from 'react';
import {useAppDispatch} from '../../../../hooks/hooks';
import useModal from '../../../../hooks/useModal';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {FormInput} from '../../../../components/shared/form-controls/form-input';
import {FormCheckbox} from '../../../../components/shared/form-controls/form-checkbox';
import CreateNewButton from '../../../../components/shared/create-new-button';
import CreateModal from '../../../../components/shared/modal/create-modal';
import {REmployees, Role} from '../../../../features/roles/types';
import {addRole, getRoles} from '../../../../features/roles';
import {AutoCompleteItemValue} from '../../../../components/shared/form-controls/form-autocomplete-tag/types';
import FormAutocomplete from '../../../../components/shared/form-controls/form-autocomplete-tag';
import {RolesFormHandbook} from '../../types';
import FormAccordion from '../../../../components/shared/form-controls/form-accordion';
import {
    AccordionDetailImpl,
    AccordionGroup,
    AccordionGroupImpl
} from '../../../../components/shared/form-controls/form-accordion/types';
import {filterRoles} from '../../../../features/roles/filter';
import { Lx } from '../../../../i18n/consts';
import { useIntl } from 'react-intl';

interface CreateForm {
    name: string,
    isActive: boolean,
    permissions: number[],
    employees: REmployees[],
    canEditEmployees?: boolean,
    canEditPermissions?: boolean,
}

export default function CreateRoleModal(props: RolesFormHandbook) {
    const dispatch = useAppDispatch();
    const {open, onToggle} = useModal();
    const intl = useIntl();
    const {permissions, employees} = props;

    const initialValue: CreateForm = {
        name: '',
        isActive: true,
        permissions: [],
        employees: [],
        canEditEmployees: false,
        canEditPermissions: false
    };

    const validateName = async (name: string): Promise<boolean> => {
        name = name.toLowerCase();
        if (name === '') {
            return Promise.resolve(false);
        }
        return await dispatch(filterRoles(name)).unwrap().then((values) => {
            return values.length < 1 || values.some(value => value.name.toLowerCase() !== name);
        });
    }

    const add = async (values: CreateForm) => {
        const updated: Role = {
            id: 0,
            name: values.name,
            isActive: values.isActive,
            employees: values.employees,
            permissions: values.permissions,
            canEditPermissions: values.canEditPermissions ?? false,
            canEditEmployees: values.canEditEmployees ?? false,
        }
        return dispatch(addRole(updated)).unwrap().then(() => {
            dispatch(getRoles(0));
            onToggle();
        })
    }

    const validationScheme: Yup.Schema<CreateForm> = Yup.object().shape({
        name: Yup.string()
            .max(45, 'Must be 45 characters or less')
            .required(intl.formatMessage({ id: Lx.Form.NAME_REQUIRED_FORMAT }, { s: intl.formatMessage({ id: Lx.General.ROLE }) }))
            .test(
                'role-name-backend-validation',
                'Role name is taken',
                async (name: string) => { return await validateName(name);}
            ),
        isActive: Yup.boolean().required(),
        permissions: Yup.array().required().min(0),
        employees: Yup.array().of(Yup.object().shape({
            id: Yup.number().required(),
            name: Yup.string().required(),
            isDefault: Yup.boolean().required(),
        })).required().min(0),
    })

    const accordionGroups: AccordionGroup[] = permissions.map(group =>
        new AccordionGroupImpl(group.name,
            group.items.map(permission => new AccordionDetailImpl(permission.id, permission.name))
        ))

    return <>
        <CreateNewButton onClick={onToggle}/>
        {open && <Formik
            initialValues={initialValue}
            validationSchema={validationScheme}
            validateOnBlur={true}
            validateOnChange={false}
            onSubmit={add}>
            <Form>
                <CreateModal open={open} title='Role' onClose={onToggle}>
                    <FormInput name='name' label='Name' placeholder='Name' />
                    <fieldset>
                        <FormCheckbox name='isActive' label='Active'/>
                    </fieldset>
                    <FormAutocomplete name='employees' label='Employees' placeHolder='Start typing to add' items={employees as AutoCompleteItemValue[]} />
                    <label style={{marginTop:'1.1rem',marginBottom:'1.2rem',fontSize: '27px'}}>Permissions</label>
                    <FormAccordion name='permissions' groups={accordionGroups} elementType={'Switch'}/>
                </CreateModal>
            </Form>
        </Formik>}
    </>
}
