import React, {useEffect, useState} from 'react'
import {Outlet, useNavigate} from 'react-router-dom'
import styles from './main-layout.module.scss';
import Menu from '../menu';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import { getProfile } from '../../features/profile';
import {Backdrop, CircularProgress} from '@mui/material';
import {StateStatus} from '../../features/loader/types';
import {getSubscription} from '../../features/billing/subscription';
import {Part} from '../../features/profile/types';
import {getEmployee} from '../../features/employees/single';

export default function MainLayout() {
    const dispatch = useAppDispatch();
    const [appLoading, setAppLoading] = useState(false);
    const {token} = useAppSelector(store => store.auth);
    const profile = useAppSelector(store => store.profile);
    const loading = useAppSelector(store => store.loader.status);
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) navigate('auth');
        if (profile === undefined || profile.id < 1) {
            setAppLoading(true)
            dispatch(getProfile()).unwrap().then((value) => {
                if (value === undefined) {
                    setAppLoading(false)
                }
                dispatch(getEmployee(value.id))
                if (value.parts.indexOf(Part.Subscription) >= 0) {
                    dispatch(getSubscription()).then(() => {
                        setAppLoading(false)
                    })
                } else {
                    setAppLoading(false)
                }
            }).catch((e) => {
                // nothing
            })
        }

    }, [])

    return (
        <div className={styles.mainContainer}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading === StateStatus.PENDING}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Menu loading={appLoading} />
            <React.Suspense fallback={<div>Loading...</div>}>
                <Outlet />
            </React.Suspense>
            <div id='modal-timinator'></div>
        </div>
    )
}
