import React, {ReactElement, ReactNode, useEffect, useReducer} from 'react';
import styles from './month-view.module.scss';
import {InRangeResult, Month} from '../../types';
import moment from 'moment';
import DayIcon from '../../../day-icon';
import classNames from 'classnames';
import {
    MonthDayCell, MonthViewBodyProps,
    MonthViewProps,
    MonthViewReducerState,
    MonthViewReducerUpdateAction,
    MonthViewReducerUpdateActionType, MonthViewRowCellProps, MonthViewRowProps
} from './types';
import {isMonthDayCellArray} from './utils';

const TOTAL_DAYS = 7;

const MonthRowCell = (props: MonthViewRowCellProps) => {
    const isInRangeResult = props.isInRange?.();
    return(<div className={classNames({
        [styles.cell]: true,
        [styles.day]: props.isDay,
        [styles.rangeSelected]: isInRangeResult ? isInRangeResult.commit : false,
        [styles.rangePreview]: isInRangeResult ? !isInRangeResult.commit : false,
        [styles.start]: isInRangeResult ? isInRangeResult.start : false,
        [styles.end]: isInRangeResult ? isInRangeResult.end : false,
        [styles.rangeEnabled]: props.isRangeEnabled && props.isDay,
        [styles.header]: props.header && props.header
    })} onMouseEnter={() => props.onOver?.()} onMouseLeave={() => props.onLeave?.()}>
        {props.children}
    </div>)
}

const MonthRow = (props: MonthViewRowProps) => {
    return (<div className={styles.row}>
        {
            isMonthDayCellArray(props.children) ? 
                props.children.map((x, index) => (
                    <MonthRowCell key={index}
                        header={props.header}
                        isDay={x.day !== 0}
                        isRangeEnabled={props.isRangeEnabled}
                        onOver={() => props.onDayOver?.(x.day)}
                        onLeave={() => props.onDayLeave?.(x.day)}
                        isInRange={() => props.isInRange(x.day)}>{
                            x.content
                        }</MonthRowCell>))
                : props.children.map((x, index) => (<MonthRowCell key={index} header={props.header} isDay={false} isRangeEnabled={false}>{x}</MonthRowCell>))
        }
    </div>)
}

const WeekDaysRow = () => {
    return(<MonthRow header={true} isRangeEnabled={false} isInRange={() => undefined}>{ moment.weekdaysShort(true).map((day, index) => (<span key={index} className={styles.weekDay}>{day}</span>)) }</MonthRow>)
}

/**
 * Example
 * MAY 2024
 * [
 *     ['','', 1, 2, 3, 4, 5]
 *     [ 6, 7, 8, 9,10,11,12]
 *     [13,14,15,16,17,18,19]
 *     [20,21,22,23,24,25,26]
 *     [27,28,29,30,31,'','']
 * ]
 */
const MonthBody = (props: MonthViewBodyProps) => {
    const all = [...props.startBlanks, ...props.days, ...props.endBlanks];
    const rows: Array<ReactNode> = [];

    let rowCells: Array<MonthDayCell> = [];
    all.forEach((element, i) => {
        if (i % TOTAL_DAYS !== 0) {
            rowCells.push(element);
        } else {
            if (rowCells.length > 0) {
                rows.push(<MonthRow key={i} isRangeEnabled={props.isRangeEnabled} isInRange={props.isInRange} onDayOver={props.onDayOver} onDayLeave={props.onDayLeave}>{rowCells}</MonthRow>)
            }
            rowCells = [];
            rowCells.push(element);
        }

        if (i == all.length - 1) {
            rows.push(<MonthRow key={i} isRangeEnabled={props.isRangeEnabled} isInRange={props.isInRange} onDayOver={props.onDayOver} onDayLeave={props.onDayLeave}>{rowCells}</MonthRow>)
        }
    })

    return(<>
        {
            rows.map((x) => (x))
        }
    </>);
}

const getBlankDays = (dayOfWeek: number, lastDay: boolean): Array<MonthDayCell> => {
    const blanks: MonthDayCell[] = []
    for (let i = 0; i < (!lastDay ? dayOfWeek < 1 ? 6 : dayOfWeek - 1 : dayOfWeek < 1 ? 0 : TOTAL_DAYS - dayOfWeek); i++) {
        blanks.push({
            day: 0,
            content: <span key={i}></span>
        })
    }
    return blanks;
}

const getDaysInMonth = (dateObject: moment.Moment, month: Month, onClick: (id: number) => void) => {
    const days: MonthDayCell[] = [];
    for (let i = 1; i <= dateObject.daysInMonth(); i++) {
        const day = month.days.find(d => d.id === i)!;
        days.push({
            day: day.id,
            content: <DayIcon key={day.id} day={i} onClick={() => onClick(i)} type={day.type == 'default' ? 'normal' : day.type == 'short' ? 'info' : 'warning'} />
        });
    }

    return days;
}

const MonthView: React.FC<MonthViewProps> = (props) => {
    const isRangeEnabled = props.range !== undefined;
    const firstDay = (moment(props.dateObject).clone().startOf('month')).day();
    const lastDay = (moment(props.dateObject).clone().endOf('month')).day();
    const initialState: MonthViewReducerState = {
        days: getDaysInMonth(props.dateObject, props.month, props.onDayClick),
        startBlankDays: getBlankDays(firstDay, false),
        endBlankDays: getBlankDays(lastDay, true)
    }
    const [monthState, monthDispatch] = useReducer((state: MonthViewReducerState, action: Partial<MonthViewReducerUpdateAction>) => {
        if (action.type === MonthViewReducerUpdateActionType.RELOAD_DAYS) {
            return { ...state, days: getDaysInMonth(props.dateObject, props.month, props.onDayClick)};
        } else if (action.type === MonthViewReducerUpdateActionType.FULL_RELOAD) {
            return {
                days: getDaysInMonth(props.dateObject, props.month, props.onDayClick),
                startBlankDays: getBlankDays(firstDay, false),
                endBlankDays: getBlankDays(lastDay, true)
            }
        }
        return state;
    }, initialState)

    useEffect(() => {
        monthDispatch({ type: MonthViewReducerUpdateActionType.RELOAD_DAYS })
    }, [props.range]);

    useEffect(() => {
        monthDispatch({ type: MonthViewReducerUpdateActionType.FULL_RELOAD })
    }, [props.month]);

    const handleIsDayInRange = (day: number): InRangeResult | undefined => {
        if (day < 1) {
            return undefined;
        }

        return props.getDayIsInRange(day);
    }

    return(<div>
        <WeekDaysRow/>
        <div className={styles.grid}>
            { monthState &&
                <MonthBody startBlanks={monthState.startBlankDays} 
                    days={monthState.days} 
                    endBlanks={monthState.endBlankDays} 
                    isRangeEnabled={isRangeEnabled} 
                    isInRange={handleIsDayInRange}
                    onDayOver={props.onDayOverEnter}
                    onDayLeave={props.onDayOverLeave}/> }
        </div>
    </div>)
}

export default React.memo(MonthView);