import * as React from 'react';
import { useFormikContext } from 'formik'
import useAutocomplete, {AutocompleteGetTagProps} from '@mui/material/useAutocomplete';
import CheckIcon from '@mui/icons-material/Check';
import {styled} from '@mui/material/styles';
import {autocompleteClasses} from '@mui/material/Autocomplete';
import styles from './form-autocomplete-tag.module.scss';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import {v4 as uuidv4} from 'uuid';
import {AutoCompleteItemValue} from './types';
import {useState} from 'react';

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
    className: string;
    label: string;
}

function Tag(props: TagProps) {
    const {label, onDelete, className, ...other} = props;
    return (
        <div className={className} {...other}>
            <span>{label}</span>
            <CancelIcon onClick={onDelete}/>
        </div>
    );
}

const ListBox = styled('ul')(
    ({theme}) => `
    & li[aria-selected='true'] {
        background-color: ${theme.palette.mode === 'dark' ? '#2b2b2b' : '#fafafa'};
        font-weight: 600;
    
        & svg {
          color: #1890ff;
        }
    }
    & li.${autocompleteClasses.focused} {
        background-color: #1F1F1F;
        cursor: pointer;
    & svg {
      color: currentColor;
    }
  }
`);

interface AutoCompleteProps extends React.InputHTMLAttributes<HTMLSelectElement> {
    placeHolder: string;
    label: string;
    name: string;
    items: AutoCompleteItemValue[];
    selectedItems?: AutoCompleteItemValue[];
}

const FormAutocomplete = (props:AutoCompleteProps) => {
    const {name, placeHolder, label, items, selectedItems} = props;

    const defaultValues:AutoCompleteItemValue[] = (selectedItems ?? []).map(s => {
        return items.find(i=> i.id == s.id) as AutoCompleteItemValue});

    const {setFieldValue} = useFormikContext();
    const {
        getRootProps,
        getInputLabelProps,
        getInputProps,
        getTagProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
        value,
        setAnchorEl,
        focused
    } = useAutocomplete({
        id: 'autocomplete-tag',
        defaultValue: defaultValues,
        multiple: true,
        options: items,
        getOptionLabel: (option) => option.name,
        filterSelectedOptions: true,
        onChange: (e, value) => {
            setFieldValue(name, value, false)
        },
        openOnFocus: false
    });

    return (
        <div className={styles.root}>
            <div {...getRootProps()}>
                <label {...getInputLabelProps}>{label}</label>
                <div ref={setAnchorEl} className={focused ? `${styles.inputWrapper} ${styles.wrapperFocus}`:`${styles.inputWrapper}`}>
                    <div className={styles.tagsWrapper}>
                        {value?.filter(x => x !== undefined).map((option: AutoCompleteItemValue, index: number) => {
                            const { key, ...tagProps } = getTagProps({index});
                            return <Tag className={styles.tag} label={option.name} key={key} {...tagProps} />
                        })}
                    </div>
                    <input
                        placeholder={placeHolder}
                        className={styles.noShadow}
                        {...getInputProps()}
                    />
                </div>
            </div>
            {groupedOptions.length > 0 ? (
                <ListBox className={styles.listBox} {...getListboxProps()}>
                    {(groupedOptions as typeof items).map((option, index) => {
                        const { key, ...optionProps } = getOptionProps({option, index});
                        return <li key={key} {...optionProps}>
                            <span>{option.name}</span>
                            <CheckIcon fontSize="small"/>
                        </li>
                    })}
                </ListBox>
            ) : null}
        </div>
    );
}

export default FormAutocomplete;