import React, {useEffect, useState} from 'react';
import {Box, Icon, Popover, Stack} from '@mui/material';
import styles from './preferences.module.scss';
import {
    getEndDayTime,
    getStartDayTime,
    getTimeZone, updateStartDayTime, updateEndDayTime,
    updateTimeZone
} from '../../../../features/settings/preferences';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import {Dropdown} from '../../../../components/shared/dropdown';
import TSwitch from '../../../../components/shared/form-controls/switch';
import {getEarlyAccessEnabled, updateEarlyAccessEnabled} from '../../../../features/settings/early-access';
import {getProfile} from '../../../../features/profile';
import { SVGS } from '../../../../assets/images';
import {FormattedMessage, useIntl} from 'react-intl';
import {dayTimes, timeZones} from './types';

export default function Preferences() {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const preferences = useAppSelector(store => store.preferences);
    const earlyAccess = useAppSelector((store) => store.earlyAccess);
    const [earlyAccessLoading, setEarlyAccessLoading] = useState(false);
    const [timezoneLoading, setTimezoneLoading] = useState(true);
    const [startdaytimeLoading, setStartdaytimeLoading] = useState(true);
    const [enddaytimeLoading, setEnddaytimeLoading] = useState(true);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    const getTwelveTimeIfNeeded = (time: string, enabled: boolean): string => {
        if (!enabled) {
            return time;
        }

        let hours = parseInt(time.split(':')[0]);
        const amOrPm = hours >= 12 ? 'PM' : 'AM';
        hours = (hours % 12) || 12;
        return `${hours}:00 ${amOrPm}`;
    }

    const dayTimesStringArray = dayTimes.map((value) => ({
        id: value.id,
        name: getTwelveTimeIfNeeded(value.time, false)
    }));

    const handleTimeZoneChange = (value: string) => {
        const timeZone = parseInt(value)
        if (timeZone === preferences.timezone){
            return;
        }
        dispatch(updateTimeZone(timeZone)).unwrap().then(() => {
            dispatch(getTimeZone());
        })
    }

    const handleStartDayTimeChange = (value: string) => {
        const dayTime = findDayTimeById(parseInt(value));
        const dayTimeWithTicks = `${dayTime}:00`;
        if (dayTimeWithTicks === preferences.startdaytime){
            return;
        }
        dispatch(updateStartDayTime(dayTimeWithTicks)).unwrap().then(() => {
            dispatch(getStartDayTime());
        })
    }

    const handleEndDayTimeChange = (value: string) => {
        const dayTime = findDayTimeById(parseInt(value));
        const dayTimeWithTicks = `${dayTime}:00`;
        if (dayTimeWithTicks === preferences.enddaytime){
            return;
        }
        dispatch(updateEndDayTime(dayTimeWithTicks)).unwrap().then(() => {
            dispatch(getEndDayTime());
        })
    }

    const findIdOfDayTime = (value: string): number => {
        return  dayTimes.find(dayTime => `${dayTime.time}:00` === value)!.id;
    }

    const findDayTimeById = (value: number): string => {
        return dayTimes.find(daytime => daytime.id === value)!.time;
    }

    const setEarlyAccessEnabled = (enabled: boolean) => {
        dispatch(updateEarlyAccessEnabled(enabled)).unwrap().then(() => {
            dispatch(getEarlyAccessEnabled());
        })
    }

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setEarlyAccessLoading(true);
        dispatch(getEarlyAccessEnabled()).then(() => {
            dispatch(getProfile());
            setEarlyAccessLoading(false);
        });
    }, [earlyAccess.enabled]);

    useEffect(() => {
        setTimezoneLoading(true);
        setStartdaytimeLoading(true);
        setEnddaytimeLoading(true);
        dispatch(getTimeZone()).then(() => {
            setTimezoneLoading(false);
        });
        dispatch(getStartDayTime()).then(() => {
            setStartdaytimeLoading(false);
        });
        dispatch(getEndDayTime()).then(() => {
            setEnddaytimeLoading(false);
        });
    }, []);

    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        gap: '16px',
        height: '100%',
        marginTop: '2rem',
        overflow: 'auto'
    }}>
        <Stack direction="column" className={styles.wrapper}>
            { !timezoneLoading && <Dropdown required={true} value={preferences.timezone} onBlur={handleTimeZoneChange}
                label={intl.formatMessage({ id: 'time_zone' })} name='timezone' items={timeZones.map((x) => ({ id: x.id, name: intl.formatMessage({ id: x.id >= 0 ? `time_z${x.id}` : `time_zw${Math.abs(x.id)}` }) }))}/> }
            { !startdaytimeLoading && <Dropdown required={true} value={findIdOfDayTime(preferences.startdaytime)} onBlur={handleStartDayTimeChange}
                label={intl.formatMessage({ id: 'start_day_time' })} name='startdaytime' items={dayTimesStringArray}/> }
            { !enddaytimeLoading && <Dropdown required={true} value={findIdOfDayTime(preferences.enddaytime)} onBlur={handleEndDayTimeChange}
                label={intl.formatMessage({ id: 'end_day_time' })} name='enddaytime' items={dayTimesStringArray}/> }
        </Stack>
        { !earlyAccessLoading &&
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} alignContent={'center'} width={'100%'} gap={'8px'}>
                <TSwitch labelProps={{ style: { margin: 0 } }} label='Enable early access' name='earlyaccessenabled' checked={earlyAccess.enabled} onChange={e => setEarlyAccessEnabled(e)}/>
                <span onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                    className={styles.iconInfo}>
                    <SVGS.ErrorIcon
                        aria-owns={open ? 'mouse-over-popover' : undefined}
                        aria-haspopup='true'/>
                </span>
                <Popover id="mouse-over-popover"
                    sx={{
                        pointerEvents: 'none',
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                    slotProps={{ paper: { className: styles.paper } }}>
                    <p className={styles.text}><FormattedMessage id='early_access_desc'/></p>
                </Popover>
            </Box>}
    </Box>
}