import {Absence, AbsenceType} from './types';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import HttpClient from '../../api/api';

export interface AbsencesState {
    items: Absence[];
}

const initialState: AbsencesState = {
    items: []
}

export const getAbsence = createAsyncThunk(
    'absences/get',
    async (absencesId: number) => {
        const response = await HttpClient.absencesApi.get<AbsenceType>(absencesId);
        return response.data;
    }
)

export const getAbsences = createAsyncThunk(
    'absences/get-many',
    async (args: { dateFrom: string, dateTo: string, employees: Array<number>, types: Array<number> }) => {
        const response = await HttpClient.absencesApi.getMany<Array<Absence>>(args.dateFrom, args.dateTo, args.employees, args.types);
        return response.data;
    }
)

export const createAbsence = createAsyncThunk(
    'absences/create',
    async (absence: Absence) => {
        const response = await HttpClient.absencesApi.create<Absence>(absence);
        return response.data;
    }
)

export const updateAbsence = createAsyncThunk(
    'absences/update',
    async (absence: Absence) => {
        const response = await HttpClient.absencesApi.update<Absence>(absence.id, absence);
        return response.data;

    }
)

export const updateAbsenceType = createAsyncThunk(
    'absences/update-type',
    async (arg: { absenceId: number, type: AbsenceType }) => {
        const response = await HttpClient.absencesApi.updateType<Absence>(arg.absenceId, arg.type);
        return response.data;
    }
)

const absenceSlice = createSlice({
    name: 'absences',
    initialState,
    reducers: {
        clearAbsences: (state) => {
            state.items = initialState.items
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAbsences.fulfilled, (state, action) => {
            state.items = action.payload;
        })
    }
})

export const { clearAbsences } = absenceSlice.actions;

export default absenceSlice.reducer;